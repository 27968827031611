import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const donamical = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Don amical" />
    <h3 className='underline-title'>Don amical</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p className="font-italic">
        (À MALAGO KAHUBANGWA na KIOYO)
      </p>
      <p>
        Ami<br />
        Je te la donne, mon amitié<br />
        Je te donne mon amitié<br />
        Premier et dernier de mes cadeaux<br />
        Amitié sincère du temps des immoraux<br />
      </p>
      <p>
        Ami<br />
        Je te le donne, mon kohi-noor<br />
        Je te donne mon diamant<br />
        Aucun Britanique ne l'emportera<br />
        Aucun Ouest-africain ne le dérobera<br />
        Amitié mâle du temps des Eve<br />
      </p>
      <p>
        Ami<br />
        Je te le donne, mon unique bien<br />
        Je te donne mon bien zaïrois<br />
        Il ne sera jamais zaïrianisé<br />
        N'en crains pas la radicalisation<br />
        Aucune rétrocession à redouter<br />
        Amitié rare du temps des vautours<br />
      </p>
      <p>
        Ami<br />
        Je n'ai rien à part mon amitié<br />
        Je te donne ma pauvre amitié<br />
        Pauvre mais fort riche pour s'offrir<br />
        Fidèle toujours fidèle pour toi<br />
        Amitié naturelle du temps des artificiels<br />
      </p>
      <p>
        Ami<br />
        Amitié rare<br />
        Amitié mâle<br />
        Amitié fidèle<br />
        Amitié naturelle<br />
        Je te la donne<br />
      </p>
      <p>
        Mon amitié<br />
        Personne ne te l'arrachera<br />
        Elle est à toi, elle pour toi<br />
        Je te la donne, mon amitié<br />
      </p>
      <p>
        Simplement<br />
        Simplement<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 2 janvier 1976</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default donamical
